.content-privacy-policy,
.content-terms-of-service {
  h2 {
    @apply text-lg font-semibold underline;
  }
  h3 {
    @apply text-lg font-semibold;
  }
  section, div, section > div {
    @apply mb-4;
  }
  a {
    @apply mx-1 text-blue-500 hover:underline;
  }
  ul {
    @apply list-disc pl-6;
  }
  ol {
    @apply list-decimal pl-6;
  }
  b {
    @apply mx-1;
  }
}