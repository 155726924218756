@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "General Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0E1218 !important;
  font-weight: 500;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main {
  min-height: calc(100vh - 426px);
}

.font-title {
  font-family: 'General Sans';
  font-weight: 700
}

.content {
  @apply max-w-screen-xl mx-6 md:mx-10 xl:mx-auto;
}

.bg-content-gradient {
  @apply bg-gradient-to-b from-white to-indigo-50;
}

.text-desc {
  @apply text-xs text-gray-500;
}

.hover-shadow1 {
  &:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    border-radius: 5px;
  }
}
